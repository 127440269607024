import React, { useEffect, useRef, useState } from 'react';
import { Link } from "react-router-dom";
import { toast } from 'react-toastify';
import Logo from '../assets/images/logo.svg';
import MinLogo from '../assets/images/mini-logo.svg';
import CheckIcon from '../assets/images/check.svg';
import Arrow from '../assets/images/arrow.svg';
import AnimationVideo from '../assets/video/animation-video.mp4';

const Main = () => {
    const baseUrl = process.env.REACT_APP_API_URL;

    const [emailForm, setEmailForm] = useState('');

    const handleChangeEmail = (e) => {
        setEmailForm(e.target.value);
    }

    const sendEmail = () => {
        const requestOptions = {
            method: 'POST',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify({ email: emailForm })
        };
        fetch(`${baseUrl}/api/v1/subscribers`, requestOptions)
            .then(response => {
                return response.json();
            })
            .then(data => {
                if (!data.success) {
                    toast.error(data.errors[0].message);
                } else {
                    toast.success(data.message);
                    setEmailForm('');
                }
            })
            .catch(error => {
                // setError(true);
            });
    }
    const [registerForm, setRegisterForm] = useState(false);

    const openRegisterForm = () => {
        setRegisterForm(true);
    }
    const animationVideoRef = useRef(null);


    useEffect(() => {
        const autoplayDelay = 1000;

        const playVideo = () => {
            if (animationVideoRef.current) {
                animationVideoRef.current.play();
            }
        };
        const autoplayTimeout = setTimeout(playVideo, autoplayDelay);

        return () => clearTimeout(autoplayTimeout);
    }, []);

    return (
        <div className='main'>
            <div className="contact-button">
                <Link to="mailto:support@chatbnb.com">Contact Support</Link>
            </div>
            <div className="container">
                <div className="logo">
                    <img src={Logo} alt=""/>
                </div>
                <div className="main-section">
                    <div className="main-box">
                        <h2>
                            <div>
                                AI Response generator for AIRBNB hosts
                                <img src={MinLogo} alt=""/>
                            </div>
                        </h2>
                        <h1>
                            <span><b>So Simple,</b></span>
                            <span><b>Just Swipe,</b></span>
                            <span><b>Try <i className='today'>today</i> <i className="now">now</i></b></span>
                        </h1>
                        <div className="arrow">
                            <span>
                                <img src={Arrow} alt=""/>
                            </span>
                        </div>
                        <ul>
                            <li>
                                <div>
                                    <img src={CheckIcon} alt=""/>
                                    Automated Messaging
                                </div>
                            </li>
                            <li>
                                <div>
                                    <img src={CheckIcon} alt=""/>
                                    Adaptive Learning
                                </div>
                            </li>
                            <li>
                                <div>
                                    <img src={CheckIcon} alt=""/>
                                    Prompt Responses
                                </div>
                            </li>
                            <li>
                                <div>
                                    <img src={CheckIcon} alt=""/>
                                    Guest Screening
                                </div>
                            </li>
                        </ul>
                        <div className={`get-started ${registerForm ? 'open' : ''}`}>
                            <div>
                                <label>
                                    <input type="email" placeholder='Enter your Email' name='email' value={emailForm} onChange={handleChangeEmail} />
                                </label>
                                {!registerForm ?
                                    <button className='get-started-button' onClick={openRegisterForm}>Get Started</button>
                                    : <button className='preregister-button' onClick={sendEmail}>Preregister</button>
                                }
                            </div>
                        </div>
                    </div>
                    <div className="video-box">
                        <div>
                            <video ref={animationVideoRef} width="100%" height="100%" playsInline muted className='animation-video' loop>
                                <source src={AnimationVideo} type="video/mp4" />
                            </video>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default Main;

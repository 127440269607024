import React from 'react';
import GreenCheckIcon from "../assets/images/green-rounded-check.svg";

const PaymentSuccess = () => {
    return (
        <div className="payment-success">
            <div className="top">
                <img src={GreenCheckIcon} alt=""/>
                <h4>Payment Successful</h4>
            </div>
            <div className="bottom">
                <p>Your payment was successful. <br/> Thank you!</p>
            </div>
        </div>
    );
}

export default PaymentSuccess;

import "./assets/styles/index.scss";
import "react-toastify/dist/ReactToastify.css";
import Main from "./pages";
import { Route, Routes } from "react-router-dom";
import PaymentSuccess from "./pages/paymentSuccess";
import PrivacyPolicy from "./pages/privacy-policy";

function App() {
  return (
    <Routes>
      <Route
        exact
        path="/"
        element={<Main />}
      />
      <Route
        exact
        path="/payment-success"
        element={<PaymentSuccess />}
      />
      <Route
        exact
        path="/privacy-policy"
        element={<PrivacyPolicy />}
      />
    </Routes>
  );
}

export default App;

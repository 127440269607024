import React from "react";
import { Link } from "react-router-dom";

const PrivacyPolicy = () => {
  return (
    <div className="container">
      <h2 className="title">ChatBNB Privacy Policy</h2>
      <span>Effective Date: March 15th 2024</span>
      <p>
        Welcome to ChatBNB! Your privacy is of utmost importance to us. This
        Privacy Policy outlines the types of information we collect from you
        while you use our mobile application ("App"), how we use your
        information, how we protect it, and the choices you have related to your
        personal information.
      </p>
      <h4 className="subTitle">Information We Collect</h4>
      <p>
        When you use ChatBNB, we collect the following types of information:
      </p>
      <div>
        <p>
          Personal Identification Information: This includes information that
          can be used to identify you, such as your name, email address, and
          profile picture. Messaging Data: Any messages you send and receive
          through our app are stored on our servers. This includes text
          messages, photos, videos, and any other media. Usage Data: We collect
          information about how you use our app, such as the features you
          interact with and the time spent on the app. Device and Connectivity
          Information: We gather information about the device you use to access
          our App, including the hardware model, operating system, unique device
          identifiers, and network information.
        </p>
      </div>
      <h4 className="subTitle">How We Use Your Information</h4>
      <p>Your information is used in the following ways:</p>
      <ul className="list">
        <li>● To provide, maintain, and improve our App and services.</li>
        <li>● To personalize your experience</li>
        <li>
          ● To communicate with you about your account or transactions with us
          and send you information about features and enhancements of our App.
        </li>
        <li>
          ● To monitor and analyze trends, usage, and activities in connection
          with our App.
        </li>
        <li>● To ensure the security and integrity of our App.</li>
      </ul>
      <h4 className="subTitle">Sharing of Your Information</h4>
      <p>
        We do not sell any data to third-party companies. Your information may
        be shared in the following circumstances:
      </p>
      <ul className="list">
        <li>
          ● With Service Providers: We may share your information with service
          providers who perform services on our behalf, such as hosting, data
          analysis, information technology, and customer service.
        </li>
        <li>
          ● For Legal Reasons: We may share information about you if we
          reasonably believe that disclosing the information is necessary to
          comply with any valid legal process, governmental request, or
          applicable law, rule, or regulation.
        </li>
        <li>
          ● With Your Consent: We may share your information with third parties
          when we have your explicit consent to do so.
        </li>
      </ul>
      <h4 className="subTitle">Data Storage and Security</h4>
      <ul className="list">
        <li>
          ● We store messaging data used in the app on our private servers.
          However, we do not store any users' credit card data.
        </li>
        <li>
          ● We implement a variety of security measures designed to maintain the
          safety and integrity of your personal information.
        </li>
      </ul>
      <h4 className="subTitle">Your Privacy Choices and Rights</h4>
      <p>
        You have certain rights and choices regarding your personal information,
        including the right to access, correct, or delete your personal
        information. You may also have the right to object to or restrict
        certain processing of your personal information.
      </p>
      <h4 className="subTitle">Changes to This Privacy Policy</h4>
      <p>
        We may update our Privacy Policy from time to time. We will notify you
        of any changes by posting the new Privacy Policy on this page and
        updating the "Effective Date" at the top of this Privacy Policy.
      </p>
      <h4 className="subTitle">Contact Us</h4>
      <p>
        If you have any questions about this Privacy Policy, please contact us
        at{" "}
        <Link to="mailto:support@chatbnb.com">
          <a className="link">support@chatbnb.com </a>
        </Link>
      </p>
    </div>
  );
};
export default PrivacyPolicy;
